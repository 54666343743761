import './bootstrap';
import 'bootstrap/js/dist/dropdown';
import '../css/app.css';
import '../css/chat.css';
import '../css/global.css';
import '../css/fonts.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import axios from './utils/axiosConfig';  
import { Inertia } from '@inertiajs/inertia';

// Importiere den CookieBanner und PWAInstallCard
import CookieBanner from './Components/CookieBanner.vue';
import PWAInstallCard from './Components/PWAInstallCard.vue';

const appName = 'My StoryPro AI';

// Zentrale Funktion zum Token-Setting
const updateCsrfToken = (newToken) => {
    if (newToken) {
        document.querySelector('meta[name="csrf-token"]')?.setAttribute('content', newToken);
        axios.defaults.headers.common['X-CSRF-TOKEN'] = newToken;
    }
};

// Initial Token setzen
const initialToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
updateCsrfToken(initialToken);

// Axios Interceptor für 401 und 419 Fehler
axios.interceptors.response.use(
    response => response,
    async error => {
        if (error.response?.status === 401) {
            window.location.href = '/login';
            return Promise.reject(error);
        }
        
        if (error.response?.status === 419) {
            try {
                const response = await axios.get('/csrf-token');
                const newToken = response.data.token;
                updateCsrfToken(newToken);
                
                // Original Request wiederholen
                const originalRequest = error.config;
                originalRequest.headers['X-CSRF-TOKEN'] = newToken;
                return axios(originalRequest);
            } catch (retryError) {
                console.error('Failed to refresh CSRF token:', retryError);
                return Promise.reject(retryError);
            }
        }
        return Promise.reject(error);
    }
);

// Setze einen globalen Listener für beforeinstallprompt
window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    window.deferredPrompt = e;
});

class SessionManager {
    constructor() {
        console.log('SessionManager initialized');
        this.heartbeatInterval = null;
        this.initHeartbeat();
        this.initVisibilityHandler();
    }

    initHeartbeat() {
        console.log('Heartbeat started - checking every 60 seconds');
        this.heartbeatInterval = setInterval(() => {
            console.log('Heartbeat checking session...');
            this.checkSession();
        }, 60000);
    }

    initVisibilityHandler() {
        console.log('Visibility handler initialized');
        document.addEventListener('visibilitychange', () => {
            console.log('Tab visibility changed:', document.visibilityState);
            if (document.visibilityState === 'visible') {
                console.log('Tab became visible - checking session...');
                this.checkSession();
            }
        });
    }

    async checkSession() {
        try {
            console.log('Making heartbeat request...');
            const response = await axios.get('/heartbeat');
            console.log('Heartbeat response:', response.status, response.data);
            if (response.status !== 200) {
                console.log('Invalid session detected (non-200 response)');
                this.handleInvalidSession();
            }
        } catch (error) {
            console.log('Session check failed:', error.response?.status, error.message);
            this.handleInvalidSession();
        }
    }

    handleInvalidSession() {
        console.log('Handling invalid session - clearing interval and redirecting');
        clearInterval(this.heartbeatInterval);
        window.location.href = '/login';
    }
}

// Inertia success event
Inertia.on('success', (event) => {
    const newToken = event.detail.page.props.csrfToken;
    updateCsrfToken(newToken);
});

document.addEventListener('DOMContentLoaded', () => {
    createInertiaApp({
        title: (title) => {
            const pageTitles = {
                'Auth/Login': 'Login',
                'Auth/Register': 'Register',
                'Chats/Index': 'Chats',
            };
        
            const pageName = title || '';
            const pageTitle = pageTitles[pageName] || title;
        
            if (pageTitle && pageTitle !== appName) {
                return `${appName} | ${pageTitle}`;
            } else {
                return appName;
            }
        },
        resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
        setup({ el, App, props, plugin }) {
            const vueApp = createApp({ 
                render: () => h('div', [
                    h(App, props),
                    h(CookieBanner),
                    h(PWAInstallCard)
                ]) 
            })
            .use(plugin)
            .use(ZiggyVue);

            vueApp.config.globalProperties.$axios = axios;
            vueApp.mount(el);
        },
        progress: false,
    });

    // Session Manager initialisieren
    new SessionManager();

    if ('serviceWorker' in navigator) {
       // Erst versuchen zu unregistrieren
       navigator.serviceWorker.getRegistrations().then(function(registrations) {
           for(let registration of registrations) {
               registration.unregister();
           }
       });

       // Dann prüfen ob noch was da ist
       setTimeout(() => {
           navigator.serviceWorker.getRegistrations().then(function(registrations) {
               if(registrations.length > 0) {
                   const alert = `
                       <div class="alert alert-warning alert-dismissible fade show mt-3 mx-3" role="alert" 
                            style="border-radius: 10px; background-color: #fef9c3; border: none;">
                           <div class="d-flex flex-column">
                               <p class="mb-2">Hey there! We've made some improvements to the app! 🚀</p>
                               
                               <p class="mb-2">To get the best experience, please refresh your browser using CTRL+F5 (Windows) or CMD+R (Mac).</p>
                               
                               <p class="mb-2">If you're still seeing outdated content after refreshing, drop us a message and we'll help you out!</p>
                               
                               <p class="mb-0">Thank you for using StoryPro AI! ✨</p>
                           </div>
                           <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                       </div>
                   `;
                   document.body.insertAdjacentHTML('afterbegin', alert);
               }
           });
       }, 1000);
    }
});
